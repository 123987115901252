import {Component, Output, EventEmitter, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {ChangeStyleguide} from '../../actions/styleguide.actions';
import {StyleguideDefinitionState} from "../../states/styleguideDefinition.state";
import {LsgState} from "../../states/styleguide.states";
import {PatternModel} from "../../models/pattern.model";

@Component({
  selector: 'lsg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  @Select(StyleguideDefinitionState.getStyleguideDefinition) styleguideDef$;
  @Select(LsgState.getAvailableStyleguides) availableStyleguides$;

  pattern?: PatternModel;

  @Output()
  sendToggle: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  sendSize: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  sendDisko: EventEmitter<number> = new EventEmitter<number>();

  constructor(private store: Store) { }

  viewports = [
    { name: 'Default', size: 'default' },
    { name: 'PC', size: '1280' },
    { name: 'Tablet', size: '1023' },
    { name: 'Mobile', size: '479' }
  ];

  private selectedViewport: string;
  private selectedStyleguide: string;

  ngOnInit() { }

  changeStyleguide() {
    this.store.dispatch(new ChangeStyleguide(this.selectedStyleguide));
  }

  toggleNavigation(){
    this.sendToggle.emit();
  }

  disko(){
    this.sendDisko.emit();
  }

  changeViewportSize(){
    this.sendSize.emit(this.selectedViewport);
  }

  openInTab = function() {
    this.store.selectOnce((state) => state.styleguideDefinition.activePattern)
      .subscribe(pattern => {
        var win = window.open(pattern.getUrl({tab: true}), '_blank');
        win.focus();
      });
  };

}
