import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {flatMap} from 'rxjs/operators';
import {LsgState, Styleguide} from './states/styleguide.states';
import { Store } from '@ngxs/store';
import {ChangeAvailableStyleguides, ChangeStyleguideDefinition} from './actions/styleguide.actions';
import {Select} from '@ngxs/store';
import {map, mergeMap, pluck} from "rxjs/internal/operators";

@Component({
  selector: 'lsg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'UI';
  viewportSize = '';
  resizeViewport = false;
  isDiskoActive = false;

  mainNav = "mainNav";

  isNavigationDisplayed: boolean = true;

  @Select(LsgState.getStyleguideName) styleguideName$;
  @Select(LsgState.getAvailableStyleguides) availableStyleguides$;

  constructor(private http: HttpClient, private store: Store) {
  }

  toggleNavigation($event){
    this.isNavigationDisplayed = !this.isNavigationDisplayed;
  }

  changeViewportSize(size: string){
    if (size === 'default'){
      this.resizeViewport = false;
    }else{
      this.resizeViewport = true;
      this.viewportSize = size;
    }
  }

  disko(timer: number){
    this.isDiskoActive = !this.isDiskoActive;
  }
}
