import { Component, OnInit } from '@angular/core';
import {Select} from '@ngxs/store';
import {StyleguideDefinitionState} from '../../states/styleguideDefinition.state';
import {style, state, animate, transition, trigger} from '@angular/animations';

@Component({
  selector: 'lsg-header',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        opacity: '1',
        display: 'block'
      })),
      state('closed', style({
        opacity: '0',
        display: 'none'
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  private isTopMenuDisplayed: boolean = false;

  headerNav = "headerNav";

  @Select(StyleguideDefinitionState.getStyleguideDefinition) styleguideDef$;

  constructor() { }

  ngOnInit() {
  }

  toggleTopMenu(){
    this.isTopMenuDisplayed = !this.isTopMenuDisplayed;
  }

}
