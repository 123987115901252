import { State, Action, StateContext, Selector } from '@ngxs/store';
import {ChangeActivePattern, ChangeStyleguideDefinition} from '../actions/styleguide.actions';
import {PatternModel} from "../models/pattern.model";

​
export interface StyleguideDefinitionModel {
  definition: Object;
  activePattern?: PatternModel
}

@State<StyleguideDefinitionModel>({
  name: 'styleguideDefinition',
  defaults: {
    definition: {},
    activePattern: undefined
  }
})
export class StyleguideDefinitionState {

  @Selector()
  static getStyleguideDefinition(lsg: StyleguideDefinitionModel) {
    return lsg.definition;
  }

  @Action(ChangeStyleguideDefinition)
  change(context: StateContext<StyleguideDefinitionModel>, action: ChangeStyleguideDefinition) {
    context.patchState({
      definition: action.definition
    });
  }

  @Action(ChangeActivePattern)
  changeActivePattern(context: StateContext<StyleguideDefinitionModel>, action: ChangeActivePattern) {
    context.patchState({
      activePattern: action.pattern
    });
  }
}
