import { BrowserModule } from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';

import { LsgState } from './states/styleguide.states';
import { StyleguideDefinitionState} from './states/styleguideDefinition.state';
import { DemoComponent } from './components/demo/demo.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SafePipe } from './safe.pipe';
import { NavFilterPipe} from './navFilter.pipe';
import {AvailableStyleguideResolver} from "./resolvers/AvailableStyleguideResolver.service";
import {StyleguideService} from "./services/styleguide.service";

const appRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, resolve: {availableStyleguides: AvailableStyleguideResolver}  },
  { path: 'demo/:scope/:type/:name', component: DemoComponent, resolve: {availableStyleguides: AvailableStyleguideResolver} }
];

let appInjector: Injector

export function getAppInjector() {
  return appInjector;
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    DemoComponent,
    DashboardComponent,
    SafePipe,
    NavFilterPipe
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsModule.forRoot([
      LsgState,
      StyleguideDefinitionState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot()
  ],
  providers: [StyleguideService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    appInjector = injector;
  }
}
