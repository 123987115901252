import {Styleguide} from "../states/styleguide.states";
import {PatternModel} from "../models/pattern.model";

export class ChangeStyleguide {
  static readonly type = '[LSG] Change Styleguide';
  constructor(public name: string) {}
}

/**
 * Action zum äbndern der verfügbaren Styleguides
 */
export class ChangeAvailableStyleguides {
  static readonly type = '[LSG] Change available Styleguides';
  constructor(public availableStyleguides: Styleguide[]) {}
}

export class ChangeStyleguideDefinition {
  static readonly type = '[LSG] Change Styleguide Definition';
  constructor(public definition: Object) {}
}

export class ChangeActivePattern {
  static readonly type = '[LSG] Change active Pattern Model';
  constructor(public pattern: PatternModel) {}
}
