import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({ name: 'filter' })
export class NavFilterPipe implements PipeTransform {

  constructor() { }

  transform(items: any[], searchText: string): any[] {
    var result = [];

    if(!items){
      return [];
    }

    if(!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter( item => {
      return item.name.toLowerCase().includes(searchText);
    });

  }
}
