import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { Select } from '@ngxs/store';

import {StyleguideDefinitionState} from '../../states/styleguideDefinition.state';

@Component({
  selector: 'lsg-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less']
})
export class NavigationComponent implements OnInit{
  @Select(StyleguideDefinitionState.getStyleguideDefinition) styleguideDef$;

  @Input('customID') customID: string;

  @Output()
  navItemSelected: EventEmitter<string> = new EventEmitter<string>();


  // TODO: Sortierung
  //  Scopes müssen sortiert werden: common immer ganz oben. Der scope=sryleguide.name immer zum schluss. Ansonsten Alphabetisch
  // Pattern-Typ: quarks, atoms, molecules, organisms, templates, pages
  // Pattern: ORDER BY NAME ASC

  constructor() {
  }

  ngOnInit() {
  }

  itemSelected(){
    this.navItemSelected.emit();
  }

}
