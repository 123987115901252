import { Injectable } from '@angular/core';
import {Observable} from "rxjs/index";
import {map, pluck} from "rxjs/internal/operators";
import {Styleguide} from "../states/styleguide.states";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class StyleguideService {

  constructor(private http: HttpClient) {}

  /**
   * Laedt alle verfuegbaren Styleguides
   * @returns {Observable<Styleguide[]>}
   */
  getAvailableStyleguides(): Observable<Styleguide[]> {
      return this.http.get('./assets/styleguides.json')
      // array mit styleguides aus json nehmen
        .pipe(pluck('styleguides'))
        // array mit verfügbaren Styleguides bekannt machen
        .pipe(map(data => {
          return data as Styleguide[];
        }));
  }

  getStyleguideDefinition(styleguideName: string): Observable<any> {
    return this.http.get(`./assets/styleguide-${styleguideName}.json`);
  }

}
