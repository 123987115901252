export class PatternModel {
  styleguide: string;
  scope: string;
  type: string;
  name: string;
  isAmp: boolean;


  constructor(scope: string, type: string, name: string, isAmp: boolean) {
    this.scope = scope;
    this.type = type;
    this.name = name;
    this.isAmp = isAmp;
  }

  getUrl(opts = { tab: false }) {
    var basePath = 'assets/patternDemos/'  + this.scope + '/' + this.type + '/' + this.name;

    if (this.isAmp) {
      return basePath + '/demoAMP.html#development=1';
    }

    if (opts.tab) {
      return basePath + '/demoTab.html';
    }

    return basePath + '/demo.html';
  }
}


