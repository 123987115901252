import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatternModel } from '../../models/pattern.model';
import {StyleguideDefinitionState} from "../../states/styleguideDefinition.state";
import {Select, Store} from "@ngxs/store";
import {ChangeActivePattern, ChangeStyleguideDefinition} from "../../actions/styleguide.actions";

@Component({
  selector: 'lsg-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.less']
})
export class DemoComponent implements OnInit {
  private demoUrl: string;

  pattern: PatternModel;

  @ViewChild('demoIframe') demoIframe;

  @Select(StyleguideDefinitionState.getStyleguideDefinition) styleguideDef$;

  constructor( private route: ActivatedRoute, private store: Store) { }

  updateDemoStyleguideStylesheet(styleguideName: string) {
    /**
     * send the update to our demo iframe
     */
    this.demoIframe && this.demoIframe.nativeElement.contentWindow.postMessage({
      event: "ChangeStyleguide",
      value: styleguideName
    }, '*');
  }

  ngOnInit() {
    // Wenn die URL angepasst wurde, muss die entsprechende Demo im iFrame geladen werden
    this.route.params.subscribe(params => {

      this.store.selectOnce((state) => state.styleguideDefinition.definition)
        .subscribe(def => {
          var navItem = def.navigation
            // Scope finden
            .find((navEntry) => navEntry.scope === params.scope)
            // Alle Pattern des gegebenen Typs finden
            .types.find((typeEntry) => typeEntry.name === params.type)
            // Ausgewähltes pattern finden
            .patterns.find((pattern) => pattern.name === params.name);


          /**
           * TODO: in der styleguide-comdirect-amp.json werden alle Pattern, auch die unter common als AMP definiert.
           * Deswegen wird dann die Falsche Demoseite aufgerufen. Entweder auch für die common Varianten demoAMP
           * generieren oder generieren der Datei berichtigen.
           *
           * QuickFix: Abgleichen, ob scope dem styleguide namen entspricht. Dann muss es ein AMP Pattern sein.
           * @type {PatternModel}
           */
          const isAmp = def.name === params.scope && navItem.amp ? true : false; // false falls der scope != dem styleguide namen ist, damit common und co. Pattern nicht als AMP definiert werden

          this.pattern = new PatternModel(params.scope ,params.type, params.name, isAmp);

          this.store.dispatch(new ChangeActivePattern(this.pattern));

          this.demoUrl = this.pattern.getUrl();
        });
    });

    this.styleguideDef$
      .subscribe(styleguideDef => {
        this.updateDemoStyleguideStylesheet(styleguideDef.name);
      });
  }
}
