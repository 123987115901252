import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ChangeAvailableStyleguides, ChangeStyleguide, ChangeStyleguideDefinition} from '../actions/styleguide.actions';
import {map} from "rxjs/internal/operators";
import {StyleguideService} from "../services/styleguide.service";
import {getAppInjector} from "../app.module";
import {HttpClient} from "@angular/common/http";

export interface LsgStateModel {
  name: string;

  // Liste mit allen verfügbaren Styleguides
  availableStyleguides: Styleguide[];
}

/**
 * Model das zum Laden der verfügabren Styleguides verwendet wird
 */
export interface Styleguide {
  // name des styleguides
  name: string;

  // URL zum Laden der Styleguide-Definition
  definitionUrl: string;
}

@State<LsgStateModel>({
  name: 'lsg',
  defaults: {
      name: window.sessionStorage.getItem('activeStyleguide') || 'comdirect',
      availableStyleguides: []
  }
})

export class LsgState {

  private styleguideService: StyleguideService

  constructor() { }

  @Selector()
  static getStyleguideName(lsg: LsgStateModel) {
    return lsg.name;
  }

  @Selector()
  static getAvailableStyleguides(lsg: LsgStateModel) {
    return lsg.availableStyleguides;
  }

  @Action(ChangeStyleguide)
  change(context: StateContext<LsgStateModel>, action: ChangeStyleguide) {

    // Falls die nächste Action nicht definiert ist, wird der alte State geladen
    let name = action.name;
    if(name === undefined) {
      name = context.getState().name;
    }

    if(!this.styleguideService){
      this.styleguideService = new StyleguideService(getAppInjector().get(HttpClient));
    }

    // Async Actions: https://ngxs.gitbook.io/ngxs/concepts/state#async-actions
    return  this.styleguideService.getStyleguideDefinition(name)
      .pipe(map(data => {

        // update state
        context.patchState({
          name
        });

        // update session storage
        window.sessionStorage.setItem('activeStyleguide', name);

        context.dispatch(new ChangeStyleguideDefinition(data));
      }));
  }

  @Action(ChangeAvailableStyleguides)
  changeAvailableStyleguides(context: StateContext<LsgStateModel>, action: ChangeAvailableStyleguides) {
    context.patchState({
      availableStyleguides: action.availableStyleguides
    });
  }
}
