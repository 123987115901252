import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Styleguide} from "../states/styleguide.states";
import {Observable, of} from "rxjs/index";
import {map} from "rxjs/internal/operators";
import {ChangeAvailableStyleguides, ChangeStyleguideDefinition} from "../actions/styleguide.actions";
import {Store} from "@ngxs/store";
import {HttpClient} from "@angular/common/http";
import {flatMap} from "rxjs/operators";
import {StyleguideService} from "../services/styleguide.service";

@Injectable({
  providedIn: 'root'
})
export class AvailableStyleguideResolver implements Resolve<any> {

  /**
   * @param {HttpClient} http - httpclient um die verfügbaren styleguides abzufragen
   * @param {Store} store - store, um die geladenen styleguides dem store hinzuzufügen
   */
  constructor(private styleguideService: StyleguideService, private store: Store) { }

  loadAvailableStyleguides() {
    return  this.styleguideService.getAvailableStyleguides()
      // array mit verfügbaren Styleguides bekannt machen
      .pipe(map((data: Styleguide[]) => {
        this.store.dispatch(new ChangeAvailableStyleguides(data));
        return data;
      }))
      // active Styleguidedefinition laden und im Store updaten
      .pipe(flatMap( data =>  this.store.selectOnce((state) => state.lsg.name)))
      .pipe(flatMap(styleguideName => {
        // Definition des Styleguides laden
        return this.styleguideService.getStyleguideDefinition(styleguideName);
      }))
      .pipe(map(data => {
        this.store.dispatch(new ChangeStyleguideDefinition(data));
      }));
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.store.selectOnce(state => state.lsg.availableStyleguides)
      .pipe(flatMap((availableStyleguides => {

        // wenn die Styleguides geladen wurden, müssen diese nicht bei jedem Routing neu gela den werden.
        if (availableStyleguides.length > 0) {
          return of(availableStyleguides);
        }

        // verfügbare styleguides initial laden
        return this.loadAvailableStyleguides();
      })));
  }
}
